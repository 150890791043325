import {
    LegacyDoorInterface,
    mapDoorBack,
} from 'components/customer/Materials/helper/doorHelper';
import {
    useDispatchMaterial,
    ActionType,
} from 'components/customer/Materials/helper/useDispatchMaterial';
import {
    defaultLoadedCarcaseSet,
    defaultLoadedExteriorSet,
} from 'components/customer/Materials/store/materialSlice';
import {Product} from 'components/customer/Product/entity';
import {getMaterials} from 'components/customer/QFPRedux/helpers/materialHelpers';
import {useAppDispatch} from 'store/customer';
import {Page} from 'store/customer/entity/Page';
import {MaterialFields} from 'components/customer/Product/helpers/getMaterialRestrictionDetails';

interface MaterialValidationData {
    isGrained?: number;
    maxHeight?: number;
    maxWidth?: number;
    thickness?: number;
    hidden?: boolean;
}

interface ValidationDataType {
    cabinet_door?: LegacyDoorInterface;
    cabinet_ext_colour?: MaterialValidationData;
    cabinet_ext_edge_colour?: MaterialValidationData;
    cabinet_carc_colour?: MaterialValidationData;
    cabinet_carc_edge_colour?: MaterialValidationData;
}

interface MaterialOptions {
    cabinet_door?: LegacyDoorInterface;
    exterior_color?: string;
    cabinet_ext_colour?: {thickness: number};
    carcase_color?: string;
    cabinet_carc_colour?: {thickness: number};
    exterior_edge_color?: string;
    carcase_edge_color?: string;
}

export const useMaterialValues = (page = Page.PRODUCT) => {
    const dispatchMaterials = useDispatchMaterial({
        page: page,
        index: 0,
    });
    const dispatch = useAppDispatch();

    return (product: Partial<Product>, materialFields?: MaterialFields) => {
        const materials = getMaterials(product);
        const validationData: ValidationDataType = {};
        const materialOptions: MaterialOptions = {};

        if (
            materials.exterior &&
            ((materialFields && materialFields.hasExterior) ||
                typeof materialFields === 'undefined')
        ) {
            dispatchMaterials(
                ActionType.ExteriorColour,
                {
                    ...materials.exterior,
                },
                false,
                false,
                false
            );
            validationData.cabinet_ext_colour = {
                isGrained: materials.exterior.is_grained ? 1 : 0,
                maxHeight: materials.exterior.length,
                maxWidth: materials.exterior.width,
                thickness: materials.exterior.thickness,
                hidden: materials.exterior.is_hidden,
            };
            materialOptions.exterior_color = materials.exterior.image;
            materialOptions.cabinet_ext_colour = {
                thickness: materials.exterior.thickness,
            };
        }

        if (
            materials.edgeExterior &&
            ((materialFields && materialFields.hasExterior) ||
                typeof materialFields === 'undefined')
        ) {
            dispatchMaterials(
                ActionType.ExteriorEdgeColour,
                materials.edgeExterior,
                false,
                false,
                false
            );
            validationData.cabinet_ext_edge_colour = {
                hidden: materials.edgeExterior.is_hidden,
            };
            materialOptions.exterior_edge_color = materials.edgeExterior.image;
        }

        if (
            materials.door &&
            ((materialFields && materialFields.hasExteriorDoor) ||
                typeof materialFields === 'undefined')
        ) {
            dispatchMaterials(
                ActionType.Door,
                materials.door,
                false,
                false,
                false
            );
            validationData.cabinet_door = mapDoorBack(materials.door);
            materialOptions.cabinet_door = mapDoorBack(materials.door);
        }

        if (
            materials.carcase &&
            ((materialFields && materialFields.hasCarcase) ||
                typeof materialFields === 'undefined')
        ) {
            dispatchMaterials(
                ActionType.CarcaseColour,
                materials.carcase,
                false,
                false,
                false
            );
            validationData.cabinet_carc_colour = {
                isGrained: materials.carcase.is_grained ? 1 : 0,
                maxHeight: materials.carcase.length,
                maxWidth: materials.carcase.width,
                thickness: materials.carcase.thickness,
                hidden: materials.carcase.is_hidden,
            };
            materialOptions.carcase_color = materials.carcase.image;
            materialOptions.cabinet_carc_colour = {
                thickness: materials.carcase.thickness,
            };
        }

        if (
            materials.edgeCarcase &&
            ((materialFields && materialFields.hasCarcase) ||
                typeof materialFields === 'undefined')
        ) {
            dispatchMaterials(
                ActionType.CarcaseEdgeColour,
                materials.edgeCarcase,
                false,
                false,
                false
            );
            validationData.cabinet_carc_edge_colour = {
                hidden: materials.edgeCarcase.is_hidden,
            };
            materialOptions.carcase_edge_color = materials.edgeCarcase.image;
        }
        dispatch(defaultLoadedExteriorSet(true, 0));
        dispatch(defaultLoadedCarcaseSet(true, 0));

        return {
            validationData,
            materialOptions,
            materials,
        };
    };
};

/**
 * Declares type with fields required for retrieving attributes
 * of cabinets from product context.
 */
type ProductContextType = {
    current: {
        cabinet: {
            attributes?: {
                style: number;
                code: string;
            };
        };
    };
};

/**
 * Declares type with fields required for checking if a material is advanced.
 */
type MaterialOptionsType = {
    cabinet_door?: {
        advanced: boolean;
    };
};

const DRAWER_ADVANCED_STYLE = 5;

/**
 * Checks the currently active product for an advanced drawer material.
 *
 * @param {ProductContextType} productDataStore The current product context
 * @param {MaterialOptionsType?} materialOptions The material type
 *
 * @return {boolean}
 */
export const checkCurrentMaterialAdvanced = (
    productDataStore: ProductContextType,
    materialOptions?: MaterialOptionsType
): boolean => {
    return (
        materialOptions?.cabinet_door?.advanced ||
        (productDataStore.current.hasOwnProperty('cabinet') &&
            productDataStore.current.cabinet.hasOwnProperty('attributes') &&
            productDataStore.current.cabinet.attributes.hasOwnProperty(
                'style'
            ) &&
            productDataStore.current.cabinet.attributes.style ===
                DRAWER_ADVANCED_STYLE &&
            productDataStore.current.cabinet.attributes.hasOwnProperty(
                'code'
            ) &&
            productDataStore.current.cabinet.attributes.code === 'Drawer')
    );
};

/**
 * Declares type with form fields required for checking if a QFP product is advanced.
 */
type QfpFormValuesType = {
    door?: {
        advanced?: number;
    };
    cabinet?: {
        attributes?: {
            style?: number;
            code?: string;
        };
    };
};

/**
 * Checks the currently active QFP product for an advanced drawer material.
 *
 * @param {QfpFormValuesType} formValues The current QFP product form values
 *
 * @return {boolean}
 */
export const checkCurrentQfpProductAdvanced = (
    formValues: QfpFormValuesType
): boolean => {
    return (
        (formValues.hasOwnProperty('cabinet') &&
            formValues.cabinet.hasOwnProperty('attributes') &&
            formValues.cabinet.attributes.hasOwnProperty('style') &&
            formValues.cabinet.attributes.style === DRAWER_ADVANCED_STYLE &&
            formValues.cabinet.attributes.hasOwnProperty('code') &&
            formValues.cabinet.attributes.code === 'DrawerQFP') ||
        (formValues.hasOwnProperty('door') &&
            formValues.door.hasOwnProperty('advanced') &&
            formValues.door.advanced === 1)
    );
};

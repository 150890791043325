import {Product} from 'components/customer/Product/entity';

export const checkCabinetTopRestriction = (product: Product) => {
    if (
        product.available_options &&
        !product.available_options.is_cabinet_top_available &&
        product.hasOwnProperty('cabinet_top')
    ) {
        const cabinetTops = product.cabinet_details.cabinet_tops;

        if (cabinetTops && cabinetTops.length > 0) {
            const selectedCabinetTop = cabinetTops.find(
                (cabinetTop) => Number(cabinetTop.id) == product.cabinet_top
            );

            if (selectedCabinetTop) {
                return `<strong>${selectedCabinetTop.name}</strong> (Cabinet Top)`;
            }
        }
    }
};

export const mapData = <T extends object, K extends keyof T>(
    field: K,
    data: T[]
) => {
    const mappedData = new Map<T[K], T[]>();

    data.forEach((datum: T) => {
        if (typeof datum === 'object' && field in datum) {
            // This is disabled here as we need field to be dynamic
            // and we have already checked that datum is an object
            // and field is a valid key of said object. If any malicious
            // code is injected into the data, it will be the responsibility
            // of the data provider.
            // eslint-disable-next-line security/detect-object-injection -- See above comment
            const key = datum[field];

            if (mappedData.has(key)) {
                mappedData.get(key).push(datum);
            } else {
                mappedData.set(key, [datum]);
            }
        }
    });

    return mappedData;
};
